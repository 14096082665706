.post-container1 {
    position: relative;
    width: 100%;
   width: 100%;
  top: 5rem;
    overflow: hidden;
   
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .post-image1 img {
    width: 100%;
    height: 100vh;
    display: block;
    
  }
  
  .post-overlay1 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    transition: background 0.3s ease;
  }

  
  .post-heading1 {
    margin: 0;
    font-size: 5em;
    font-weight: bold;
    text-align: left;
    animation: fadeIn 1s ease-out;
    width: 30rem;
}
  
.post-text1 {
    margin-top: 25px;
    font-size: 1.5em;
    text-align: right;
    animation: fadeInUp 1s ease-out;
    width: 26rem;
}
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes fadeInUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }
  









  @media only screen and (max-width: 767px){
    .post-image1 img {
      width: 100%;
      height: 21rem;
      display: block;
  }
  .post-heading1 {
    margin: 0;
    font-size: 3em;
    font-weight: bold;
    text-align: left;
    animation: fadeIn 1s ease-out;
    width: 17rem;
}
  }


  @media only screen and (min-width: 768px) and (max-width: 1023px){


  }



  