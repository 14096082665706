.card-section {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-full {
  position: relative;
  top: 100px;
  margin-bottom: 2rem;
}

.card-full h2 {
  text-align: center;
  padding: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.card {
  position: relative;
  background: #b82f3359;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.card:hover {
  transform: scale(1.005);
}

.card-image {
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  /* animation: rotateGlow 5s linear infinite; */
  height: 19rem;
  filter: blur(5px);
}

@keyframes rotateGlow {
  0% {
    box-shadow: 0 0 10px rgba(61, 61, 61, 0.5);
  }
  50% {
    box-shadow: 0 0 20px #b82f33;
  }
  100% {
    box-shadow: 0 0 10px rgba(61, 61, 61, 0.5);
  }
}

.ribbon {
  position: absolute;
  bottom: 152px;
  left: -127px;
  width: 72%;
  height: 40px;
  background-color: #b82f33;
  color: white;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: bold;
  transform: rotate(270deg);
  border-radius: 10px;
}

.card-text {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-image {
  max-width: 90%;
  max-height: 80%;
  border-radius: 10px;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

/* Styling for the second card section */
.card-section2 {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.card-full2 {
  position: relative;
  top: 100px;
}

.card-full2 h2 {
  text-align: center;
  padding: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.card-grid2 {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  width: 65%;
}

.card2 {
  position: relative;
  background: #b82f3359;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.card2:hover {
  transform: scale(1.005);
}

.card-image2 {
  width: 100%;
  border-radius: 8px;
  cursor: pointer;
  animation: rotateGlow 5s linear infinite;
}

.ribbon2 {
  position: absolute;
  bottom: 213px;
  left: -219px;
  width: 60%;
  letter-spacing: 1px;
  height: 40px;
  background-color: #b82f33;
  color: white;
  text-align: center;
  line-height: 40px;
  font-size: 21px;
  font-weight: bold;
  transform: rotate(270deg);
}

.card-text2 {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.overlay-image2 {
  max-width: 90%;
  max-height: 80%;
  border-radius: 10px;
}

.close-btn2 {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
}

/* Mobile Responsive Styles */
@media only screen and (max-width: 767px) {
  .card-full2 {
    position: relative;
    top: 66px;
  }
  .card-full2 h2, .card-full h2 {
    text-align: center;
    padding: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  .card-grid2, .card-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    width: 100%;
  }
  .ribbon, .ribbon2 {
    bottom: 78px;
    left: -99px;
    width: 66%;
    height: 40px;
    font-size: 10px;
  }
  .card-image, .card-image2 {
    width: 100%;
    height: 11rem;
  }
  .card, .card2 {
    padding: 11px;
  }
  .card-full {
    top: 65px;
  }
}

/* Tablet Responsive Styles */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* You can add specific styles for tablets here if needed */
  .ribbon {
    position: absolute;
    bottom: 152px;
    left: -135px;
    width: 131%;
    height: 40px;
    background-color: #b82f33;
    color: white;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: bold;
    transform: rotate(270deg);
    border-radius: 10px;
}
}
