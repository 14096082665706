.detaill {
    background-color: #fef5e7;
}

.poster-section2 {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    top:5rem;
    animation: fadeIn 1s ease-in-out;
}

.section-heading2 {
    font-size: 3.5rem;
    margin-bottom: 40px;
    color: #f02128;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    animation: zoomIn 1s ease-in-out;
    letter-spacing: 2px;
}

.poster-image2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.85);
    transition: filter 0.3s ease, transform 0.3s ease;
}

.poster-image2:hover {
    filter: brightness(1);
   
}

.about-section {
    padding: 60px 20px;
    background-color: #282828;
    width: 100%;
    margin: 0 auto;
    position: relative;
    top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeInUp 1s ease-in-out;
}

.about-content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    width: 100%;
    max-width: 1200px;
    margin-top: 40px;
}

.carda {
    background-color: #fff;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid #b82f33;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.carda:hover {
    box-shadow: 0px 0px 30px 15px #f0212896;
   
}

.iconar {
    color: #f02128;
    font-size: 2rem;
    margin-bottom: 0px;
    transition: color 0.3s ease;
}

.iconar:hover {
    color: #f02128;
}

.card-content {
    font-size: 1rem;
    color: #333;
    line-height: 1.8;
    
}

.card-content::before {
    content: '';
    display: block;
    width: 60px;
    height: 4px;
    background-color: #f02128;
    margin: 15px auto;
    border-radius: 2px;
}

@media only screen and (max-width: 767px) {
    .about-section {
        padding: 20px;
        top: 50px;
    }

    .section-heading2 {
        font-size: 2.5rem;
        margin-bottom: 0px;
    }

    .carda {
        padding: 15px;
    }

    .iconar {
        font-size: 1.8rem;
        margin-bottom: 10px;
    }

    .card-content {
        font-size: 1rem;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0.8);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
