.thankyou-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
   background-color: #fff;
    padding: 20px;
    flex-direction: row;
    text-align: center;
    margin-top: 2.65rem;
}
  
  .thankyou-content {
    background-color: #ffffff00;
    padding: 40px;
    border-radius: 15px;
    
    width: 100%;
    max-width: 800px;
    transform: translateY(-50px);
    animation: fadeIn 0.8s ease-in-out forwards;
  }
  
  .thankyou-heading {
    font-size: 2.4rem;
    color: #2d2d2d;
    font-weight: bold;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 7rem;
}
  
  .thankyou-message {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 40px;
    font-weight: 300;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
  }
  
  .cta-button {
    text-decoration: none;
    padding: 14px 35px;
    font-size: 1.1rem;
    color: #fff;
    border-radius: 50px;
    font-weight: bold;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
    border: none;
    display: inline-block;
  }
  
  .home-button {
    background: #b82f33;
  }
  
  .home-button:hover {
    background: #b82f33;
    transform: scale(1.05);
    color: #fff;
  }
  
  .contact-button {
    background: #b82f33;
  }
  
  .contact-button:hover {
    background:#b82f33;
    transform: scale(1.05);
    color: #fff;
  }
  
  .thankyou-image-container {
    width: 100%;
    max-width: 1000px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  
  .thankyou-image {
    width: 100%;
    max-height: 450px;
    object-fit: cover;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    animation: zoomIn 1.2s ease-in-out forwards;
  }
  
  /* Animations */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes zoomIn {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    .thankyou-heading {
        font-size: 2rem;
        margin-top: 4rem;
    }
  
    .thankyou-message {
      font-size: 1rem;
    }
  
    .cta-buttons {
      flex-direction: column;
      gap: 15px;
    }
  
    .thankyou-image {
      max-height: 300px;
    }
  }
  




  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .cta-buttons {
        flex-direction: row;
        gap: 15px;
    }
  }
