.gallery {
    padding: 20px;
    background-color: #131212;
    position: relative;
    top: 75px;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}
  
  .card-g {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  
  }
  
  .card-g:hover {
    box-shadow: 0 8px 16px rgba(255, 255, 255, 0.723);
  }
  
  #card-g-0 {
    background-color: #f0f0f0;
    
}
  
  #card-g-1 {
    /* Styles specific to the second card */
    background-color: #e0e0e0;
  }
  
  #card-g-2 {
    /* Styles specific to the third card */
    background-color: #d0d0d0;
  }
  
  #card-g-3 {
    /* Styles specific to the fourth card */
    background-color: #c0c0c0;
  }
  
  .gallery-image {
    width: 100%;
    height: 19rem;
    display: block;
    transition: transform 0.3s;
}
  
  .text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    padding: 20px;
    background: #fff;
    color: #333;
  }
  
  .text-content h3 {
    margin: 0;
    font-size: 3.5rem;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
    width: 20rem;
}
  
.text-content p {
    margin: 0;
    font-size: 1.6rem;
    text-transform: uppercase;
    color: #666;
}
  
  .overlay3 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .overlay-content3 {
    position: relative;
    width: 80%;
    max-width: 900px;
  }
  
  .overlay-image3 {
    width: 100%;
    height: 31rem;
    border-radius: 8px;
    animation: fadeIn 0.5s ease;
}
  
  .close-button3 {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.5rem;
    color: #333;
    transition: background 0.3s;
  }
  
  .close-button3:hover {
    background: rgba(255, 255, 255, 1);
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  




  @media only screen and (max-width: 767px){
    .grid-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
  }
  .gallery-image {
    width: 100%;
    height: 13rem;
    display: block;
    transition: transform 0.3s;
}
.text-content h3 {
  margin: 0;
  font-size: 3.4rem;
  font-weight: 700;
  color: #333;
  text-transform: uppercase;
  width: 20rem;
}
.text-content p {
  margin: 0;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #666;
}
  }



  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .text-content h3 {
      margin: 0;
      font-size: 1.6rem;
      font-weight: 700;
      color: #333;
      text-transform: uppercase;
      width: 11rem;
  }
  .text-content p {
    margin: 0;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #666;
}
  }
