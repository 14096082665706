.details-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
  /* padding: 25px; */
  position: relative;
  top: 5rem;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding-top: 45px;
  padding-bottom: 45px;
}
  
.detail-item {
    display: flex;
    align-items: center;
    gap: 15px;
    align-content: center;
}
  
.detail-icon {
  font-size: 80px;
  color: #ffffff;
  background-color: #01010100;
  padding: 0px;
  border-radius: 18%;
}
.idd {
  background-color: #b82f33;
  padding: 10px;
  border-radius: 5%;
}
  
  h4 {
    margin: 0;
    font-size: 18px;
    color: #333;
  }
  
  p {
    margin: 5px 0;
    font-size: 16px;
    color: #555;
  }
  




  
@media only screen and (max-width: 767px){
  .details-section {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    position: relative;
    top: 5rem;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  .details-section {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 20px;
    position: relative;
    top: 5rem;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
}
.thumbnails1 img, .thumbnails2 img, .thumbnails4 img {
  width: 218px;
  height: 108px;
}
}