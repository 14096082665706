.connect {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    gap: 20px;
    position: relative;
    top: 5rem;
    background: #dedede;
}
  
  .left-con {
    flex: 60%;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    border: 4px solid #b62e32;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .left-con iframe {
    width: 100%;
    height: 100%;
    border-radius: 15px;
   
    transition: transform 0.3s ease-in-out;
  }
  
  .left-con iframe:hover {
    transform: scale(1.005);
  }
  
  .right-con {
    flex: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #b62e32;
    border-radius: 15px;
    padding: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .right-con h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #ffffff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
}
  
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    font-weight: 600;
    margin-bottom: 2px;
    color: #fcfcfc;
    font-family: 'Arial', sans-serif;
}
  
  .form-group input, .form-group textarea {
    padding: 12px;
    border: 1px solid #ddd;
    background-color: #dedede;
    color: #000000;
    border-radius: 8px;
    transition: border-color 0.3s ease-in-out;
  }
  
  .form-group input:focus, .form-group textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .send-button {
    background-color: #000000;
    color: #fff;
    border: none;
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 1.3rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .send-button:hover {
    background-color: #161616;
    transform: scale(1.003);
  }
  




  @media only screen and (max-width: 767px){
    .connect {
      display: flex;
      justify-content: space-between;
      padding: 16px;
      gap: 20px;
      position: relative;
      top: 5rem;
      background: #dedede;
      flex-direction: column;
  }
  .form-group label {
    font-weight: 600;
    margin-bottom: 3px;
    color: #fcfcfc;
    font-family: 'Arial', sans-serif;
}
  
  }




  @media only screen and (min-width: 768px) and (max-width: 1023px){
    .connect {
      display: flex;
      justify-content: space-between;
      padding: 25px;
      gap: 20px;
      position: relative;
      top: 5rem;
      background: #dedede;
  }
  .left-con {
    flex: 50% 1;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    border: 4px solid #b62e32;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.right-con {
  flex: 50% 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #b62e32;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

  }

