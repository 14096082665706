.amenities-section {
    padding: 2rem;
    background-color: #f9f9f9;
    text-align: center;
    position: relative;
    top: 5rem;
}
  
.amenities-title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #b82f33;
    font-weight: 800;
}
  

  
.amenity-card {
  padding: 1rem;
  background-color: #c6c6c6;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
  width: 16rem !important;
  height: 14rem;
}
  
  .amenity-card:hover {
    transform: translateY(-1px);
  }
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 3px;
}

.slick-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background-color: #000000;
  border-radius: 50%;
  color: #fff;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slick-arrow:hover {
  background-color:#b82f33;
}

.slick-prev {
  left: -2rem;
}

.slick-next {
  right: -2rem;
}

  .amenity-icon {
    font-size: 3rem;
    color: #000000;
    margin-bottom: 1rem;
  }
  
  .amenity-name {
    font-size: 1.25rem;
    color: #000000;
  }
  



  
@media only screen and (max-width: 767px){

  .amenities-section {
    padding: 2rem;
    background-color: #f9f9f9;
    text-align: center;
    position: relative;
    top: 4rem;
}
.amenities-slider .slick-dots {
  position: absolute;
  bottom: -60px;
  display: block;
  width: 100%;
  padding: 0px;
  margin: 0;
  list-style: none;
  text-align: center;
  display: flex;
}
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  
}