.luxury-homes-section {
  position: relative;
  top: 5rem;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.subheading{
  font-weight: 700;
}
.container2 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.section-heading {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #b82f33;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}

.section-text {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 20px;
  text-align: justify;
  letter-spacing: 0.5px;
}

@media only screen and (max-width: 767px) {
  .luxury-homes-section {
    padding: 1rem;
    top: 3rem;
  }

  .section-heading {
    font-size: 2rem;
    color: #b82f33;
    margin-bottom: 15px;
    text-align: center;
  }

  .section-text {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
    text-align: justify;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .section-text {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 15px;
    text-align: justify;
  }
}
