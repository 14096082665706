.location-section {
  display: flex
;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: rgb(255, 255, 255);
  margin: auto;
  flex-direction: row;
  gap: 1rem;
}
  .full-l{
    width: 100%;
    position: relative;
  background-color: rgb(255, 255, 255);
    top: 5rem;
  }
  .heading-l{
    text-align: center;
    font-weight: 700;
    padding: 2rem;
    color: #b82f33;
  }
  
  .L-l {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
  }
  
  .L-l img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .L-r {
    flex: 1 1;
    padding-left: 2rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    align-items: center;
}
  
.L-r h2 {
    font-size: 3.5rem;
    color: #333;
    margin-bottom: 0.5rem;
    font-weight: 700;
}
  
.L-r h5 {
    font-size: 2rem;
    color: #777;
    width: 70%;
    margin-bottom: 1.5rem;
    text-align: center;
}
  
.call-now {
    display: flex;
    align-items: center;
    background-color: #b82f33;
    padding: 0.75rem 1.5rem;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-decoration: none;
}
  .call-now:hover {
    background-color: #ec5d61;
    color: white;
  }
  
  .call-icon {
    margin-right: 0.5rem;
    font-size: 1.5rem;
  }
  


  
@media only screen and (max-width: 767px){

  .location-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: rgb(255, 255, 255);
    margin: auto;
    flex-direction: column;
    align-content: center;
}
.L-r h5 {
  font-size: 2rem;
  color: #777;
  width: 100%;
  margin-bottom: 1.5rem;
  text-align: center;
}
.L-r {
  flex: 1 1;
  padding-left: 0rem;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  align-items: center;
  align-content: center;
}
.L-r h2 {
  font-size: 3.5rem;
  color: #333;
  margin-bottom: 0.5rem;
  font-weight: 700;
  text-align: center;
}
.heading-l {
  text-align: center;
  font-weight: 700;
  padding: 1rem;
  color: #b82f33;
}
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  .location-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: rgb(255, 255, 255);
    margin: auto;
    flex-direction: column;
}
}