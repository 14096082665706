* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.slider {
  height: 86vh;
  width: 100%;
  position: relative;
  margin: auto;
  top: 82px;
}

.slider .slide {
  display: none;
  height: 100%;
  width: 100%;
}

.slider .slide img {
  height: 100%;
  width: 100%;
  /* filter: contrast(90%); */
  object-fit: fill;
}

.slider .slide .caption {
  position: absolute;
  bottom: 88px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 22px;
  color: #ffffff;
  padding: 8px 16px;
  background-color: rgb(15 65 43 / 69%);
  border-radius: 4px;
}

.slider a.prev,
.slider a.next {
  position: absolute;
  top: 50%;
  font-size: 30px;
  cursor: pointer;
  user-select: none;
  color: #b82f33;
  padding: 12px;
  transition: 0.2s;
}

.slider a.prev:hover,
.slider a.next:hover {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}

.slider .next {
  right: 20px;
}

.slider .prev {
  left: 20px;
}

.show {
  display: block;
  animation: fade 0.5s ease-out;
}

@keyframes fade {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.div-card {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  background-color: #dc3545cf;
  padding: 20px;
  border-radius: 10px;
  max-width: 300px;
  z-index: 98;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  display: flex;
  flex-direction: column;
}

/* Title */
.div-card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #ffffff;
}
/* Title */
.div-card-title1 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0px;
  color: #ffffff;
}

/* Location */
.div-card-location {
  font-size: 13px;
  color: #ffffff;
  margin-bottom: 1px;
}

/* Subtitle */
.div-card-subtitle {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
  color: #fffefe;
}

/* Pricing */
.div-card-pricing {
  margin-bottom: 4px;
}

.pre-launch-price,
.launch-price {
  background-color: #ec422b;
  padding: 5px;
  margin-bottom: 0px;
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #fffefe;
}

/* Features List */
.div-card-features {
  list-style: none;
  margin-bottom: 10px;
  padding-left: 0;
}

.div-card-features li {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 1px;
}

/* Save */
.div-card-save {
  background-color: #313030;
  color: white;
  padding: 5px;
  text-align: center;
  border-radius: 19px;
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Booking */
.div-card-booking {
  font-size: 15px;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 4px;
}

/* Offer */
.div-card-offer {
  font-size: 10px;
  color: #ffffff;
  margin-bottom: 16px;
  text-align: center;
}

/* Enquire Now Button */
.div-card-enquiry {
  background-color: #020202;
  color: white;
  padding: 5px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  border: none;
  transition: background-color 0.3s ease;
}

.div-card-enquiry:hover {
  background-color: #45a049;
}

/* Mobile view adjustments */
@media only screen and (max-width: 767px) {
  .div-card {
    top: 43.7%;
    left: 5px;
    display: flex
;
    transform: translateY(-50%);
    padding: 3px;
    max-width: 180px;
}
  .div-card-features li {
    font-size: 8px;
    color: #ffffff;
    margin-bottom: 1px;
    text-align: center;
    letter-spacing: 1px;
}

.div-card-title {
  font-size: 10px;
  text-align: center;
  margin-top: 0;
}
  .div-card-title1 {
    font-size: 13px;
    text-align: center;
  }

  .div-card-location, .div-card-subtitle {
    font-size: 8px;
    letter-spacing: 1px;
    text-align: center;
}

.pre-launch-price, .launch-price {
  font-size: 8px;
  text-align: center;
  letter-spacing: 1px;
  padding: 1px;
}

  .div-card-save {
    font-size: 16px;
  }

  .div-card-booking, .div-card-offer {
    font-size: 9px;
    margin-bottom: 0;
}
.div-card-features {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0;
}

.div-card-enquiry {
  font-size: 8px;
  padding: 3px 10px;
  letter-spacing: 1px;
}
}

/* Tablet view adjustments */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .div-card {
    left: 15px;
    padding: 18px;
    max-width: 280px;
    display: flex;
  }
  .div-card-features li {
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 1px;
}
.div-card-features {
  list-style: none;
  margin-bottom: 0px;
  padding-left: 0;
}


  .div-card-title {
    font-size: 14px;
    margin-top: 0;
  }
  .div-card-title1 {
    font-size: 15px;
  }

  .div-card-location,
  .div-card-subtitle {
    font-size: 12px;
  }

  .pre-launch-price, .launch-price {
    font-size: 11px;
    letter-spacing: 1px;
    padding: 2px;
}

  .div-card-save {
    font-size: 17px;
  }

  .div-card-booking,
  .div-card-offer {
    font-size: 14px;
  }

  .div-card-enquiry {
    font-size: 12px;
}
.div-card-offer {
  font-size: 10px;
  color: #ffffff;
  margin-bottom: 7px;
  text-align: center;
}
}


/* Mobile view adjustments */
@media only screen and (max-width: 767px) {
  .slider {
    height: 19rem;
    width: 100%;
    position: relative;
    margin: auto;
    top: 82px;
  }

  .slider .slide img {
    height: 16.5rem;
    width: 100%;
    object-fit: fill;
  }

  .slider .slide .caption {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    color: #ffffff;
    padding: 8px 16px;
    background-color: rgb(15 65 43 / 69%);
    border-radius: 4px;
  }

  
}

/* Tablet view adjustments */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .slider {
    height: 70vh;
  }

  .slider .slide .caption {
    font-size: 18px;
    bottom: 70px;
  }

 
}
