/* General styles */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

nav {
  z-index: 99;
  width: 100%;
  position: fixed;
  height: 84px;
  top: 0;
  background: linear-gradient(123deg, #ffffff 22%, #b82f33 17%);
}
nav .wrapper-nav {
  position: relative;
  max-width: 1300px;
  padding: 0px 13px;
  height: 84px;
  line-height: 70px;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.wrapper-nav .logo a {
  color: #f2f2f2;
  font-size: 30px;
  font-weight: 600;
  text-decoration: none;
}
 .nav-links {
  display:inline-flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0;
}
.wrapper-nav{
  display: none;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0;
}
.nav-links li {
  list-style: none;
}
.nav-links li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 23px;
  font-weight: 500;
  padding: 9px 15px;
  /* border-radius: 5px; */
  transition: all 0.3s ease;
  border-top-left-radius: 20px;
}
.nav-links li a:hover {
  background: #ffffff;
  color: #000;
}
.butt-nav {
  background: linear-gradient(to bottom right, #000000, #030303);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  margin-left: 1rem;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system,system-ui,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}
.butt-nav:not([disabled]):focus {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(241, 96, 13, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}
.butt-nav:not([disabled]):hover {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(232, 145, 15, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}
.nav-links .mobile-item {
  display: none;
}
.logo-head {
  width: 12rem;
  height: 4rem;
}
.nav-links .drop-menu, .nav-links .drop-menu2 {
  position: absolute;
  background: #4d4239;
  line-height: 45px;
  top: 65px;
  opacity: 0;
  visibility: hidden;
  z-index: 0;
  padding-left: 0;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.nav-links .drop-menu {
  width: 180px;
  right: 242px;
}
.nav-links .drop-menu2 {
  width: 220px;
  right: 435px;
}
.nav-links li:hover .drop-menu, .nav-links li:hover .drop-menu2 {
  transition: all 0.3s ease;
  opacity: 1;
  visibility: visible;
}
.drop-menu li a, .drop-menu2 li a {
  width: 100%;
  display: block;
  padding: 0 0 0 15px;
  font-weight: 400;
  border-radius: 0px;
}
.mega-box {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 30px;
  top: 85px;
  opacity: 0;
  visibility: hidden;
}
.mega-box .content {
  background: #242526;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0 6px 10px rgba(0,0,0,0.15);
}
.mega-box .content .row {
  width: calc(25% - 30px);
  line-height: 45px;
}
.content .row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content .row header {
  color: #f2f2f2;
  font-size: 20px;
  font-weight: 500;
}
.content .row .mega-links {
  margin-left: -40px;
  border-left: 1px solid rgba(255,255,255,0.09);
}
.row .mega-links li {
  padding: 0 20px;
}
.row .mega-links li a {
  padding: 5px 0;
}
.menu-btn {
  display: none;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    position: absolute;
    background: #1c1b1b;
    top: 84px;
    left: 0;
    width: 100%;
    height:auto;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 99;
  }
  .nav-links.active {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    flex-direction: row;
    justify-content: center;
}
  .nav-links li {
    text-align: center;
    padding: 0px 0;
  }
  .nav-links li a {
    font-size: 20px;
  }
  .menu-btn {
    display: block;
    font-size: 30px;
    color: #f2f2f2;
    cursor: pointer;
  }
  .logo-head {
    width: 5rem;
    height: 3rem;
  }
}

/* Form Overlay Styles */
.form-overlay {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.form-container {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  width: 80%;
  max-width: 500px;
}

.form-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

form {
  display: flex;
  flex-direction: column;
}

form input,
form textarea {
  margin-bottom: 10px;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

form button {
  padding: 0.5rem;
  background: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #fff;
  height: 3rem;
  background-color: transparent;
  /* overflow-x: scroll; */
}

.form-group textarea.invalid {
  border-color: red;
}

form button:hover {
  background: #333;
}





@media only screen and (max-width: 767px){
  
  .nav-links li a {
    color: #ffffff;
    text-decoration: none;
    font-size: 23px;
    font-weight: 500;
    padding: 1px 6px;
    border-radius: 5px;
    transition: all 0.3s ease;
}
nav {
  z-index: 99;
  width: 100%;
  position: fixed;
  height: 84px;
  top: 0;
  background: linear-gradient(117deg, #ffffff 36%, #b82f33 17%);
}
}

@media only screen and (min-width: 768px) and (max-width: 1023px){
  
}