.privacy-policy {
    padding: 60px 20px;
    background-color: #f4f6f8;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #333;
    position: relative;
    top: 2rem;
}
.hh {
  padding: 2rem;
  font-size: 2.5rem;
}
  
  .privacy-policy .container {
   
    margin: 0 auto;
    background-color: #ffffff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    margin-bottom: 5rem;
  }
  
  .privacy-policy h1 {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 40px;
    color: #2c3e50;
  }
  
  .privacy-policy h2 {
    font-size: 1.8em;
    margin-top: 40px;
    margin-bottom: 20px;
    color: #34495e;
    border-bottom: 2px solid #ecf0f1;
    padding-bottom: 10px;
  }
  
  .privacy-policy p {
    line-height: 1.8;
    margin-bottom: 20px;
    font-size: 1.05em;
  }
  
  .privacy-policy ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .privacy-policy ul li {
    margin-bottom: 10px;
  }
  
  .privacy-policy a {
    color: #3498db;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .privacy-policy {
      padding: 40px 10px;
    }
  
    .privacy-policy .container {
      padding: 20px;
    }
  
    .privacy-policy h1 {
      font-size: 2em;
    }
  
    .privacy-policy h2 {
      font-size: 1.5em;
    }
  
    .privacy-policy p {
      font-size: 1em;
    }
  }
  